<template>
  <v-card
    flat
    class="banner py-3 px-4"
    :color="color"
    :width="widthBanner"
  >
    <v-row
      no-gutters
      align="start"
    >
      <v-col cols="1">
        <TIconSvg
          :name="iconName"
          class="icon"
          :height="heightIcon"
          :width="widthIcon"
          :view-box="viewBox"
          :fill="fill"
          :stroke="stroke"
        />
      </v-col>
      <v-col cols="10">
        <span class="banner-info__text">{{text}}</span>
      </v-col>
      <v-col cols="1">
        <v-btn
          v-if="hasCloseIcon"
          icon
          small
          @click="$emit('close')"
        >
          <TIconSvg
            :name="'close'"
            height="16"
            width="16"
            view-box="0 0 24 24"
            fill="#8392A3"
            stroke="#8392A3"
          />
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TIconSvg from '@/components/Common/TIconSvg';

export default {
  name: 'SBanner',
  components: {
    TIconSvg,
  },

  props: {
    iconName: {
      type: String,
      default: 'danger-triangle',
    },
    fill: {
      type: String,
      default: '#FB5858',
    },
    stroke: {
      type: String,
      default: '#FB5858',
    },
    text: {
      type: String,
      default: '',
    },
    hasCloseIcon: {
      type: Boolean,
      default: true,
    },
    heightIcon: {
      type: String,
      default: '18',
    },
    widthIcon: {
      type: String,
      default: '18',
    },
    viewBox: {
      type: String,
      default: '0 0 22 22',
    },
    widthBanner: {
      type: String,
      default: '338',
    },
    color: {
      type: String,
      default: '#EFF3FA',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/scss/variables';
@import 'assets/variables';
.v-text-field--outlined  >>> fieldset {
  border: none !important;
}
//.theme--light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
//  border-bottom: none !important;
//}
.banner {
  border-radius: 8px !important;
}
.banner-info__text {
  font: $t4-regular;
}
</style>
