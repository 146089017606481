<template>
  <div class="auth-template">
    <template>
      <slot name="heading-follower" />
    </template>
    <template>
      <slot name="form" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'AuthTemplate',
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';
@import '~/assets/scss/_mixins';

.auth-template {
  .t-field__field {
    font: $t1-regular;
    letter-spacing: .0125rem;
  }
}
</style>
