<template>
  <AuthTemplate class="login">
    <template slot="form">
      <form
        @submit.prevent="login"
        @keydown.enter="login"
      >
        <TInput
          v-model="formData.phone"
          v-validate="{
            required: true,
            regex: regex.phoneRegex,
          }"
          v-mask="$options.phoneMask"
          :error-messages="errors.collect('phone')"
          type="tel"
          name="phone"
          :label="$t('Phone number')"
          placeholder="+7 (000) 000-00-00"
          :disabled="isLoading"
          large
          class="login__input"
        />
        <TInput
          v-model="formData.password"
          v-validate="{
            required: true,
            min: [6],
          }"
          :error-messages="errors.collect('password')"
          :type="isPasswordShown ? 'text' : 'password'"
          name="password"
          :label="$t('Password')"
          :disabled="isLoading"
          large
          class="mb-1 login__input login__input--password"
        >
          <template #actions>
            <TButton
              transparent
              :icon="isPasswordShown ? 'eye' : 'eye-input'"
              :icon-stroke="formData.password ? '#347AF0' : '#A7B5CE'"
              :icon-view-box="'0 0 24 24'"
              icon-width="24"
              icon-height="24"
              @click="showHidePassword"
            />
          </template>
          <template #toast>
            <TToast
              class="check__toast mt-4"
              :has-button="false"
              error
              position-static
            >
              <div slot="body">
                {{errors.collect('password')[0]}}
              </div>
            </TToast>
          </template>
        </TInput>
        <div
          v-if="serverError"
          class="mb-3 error-text"
        >
          {{serverError}}
        </div>
        <div
          v-if="forbiddenError"
          class="pt-3"
        >
          <SBanner
            width-banner="420"
            color="#FFEBEC"
            :has-close-icon="false"
            :text="forbiddenErrorText"
          />
        </div>
        <div class="login__wrapper mt-6">
          <TButton
            :loading="isLoading"
            type="submit"
            class="login__button"
            large
          >
            {{$t('auth.Login')}}
          </TButton>
          <TLink
            :is-nuxt-link="false"
            :is-underlined="false"
            :class="serverError ? 'mb-2' : ''"
            @click.prevent="$emit('login-form', 'password-recovery')"
          >
            {{$t('auth.Recover or change password')}}
          </TLink>
        </div>
      </form>
    </template>
  </AuthTemplate>
</template>

<script>
// Utils
import { mapMutations } from 'vuex';
import { regex, unmaskValue } from '~/assets/js/utils';

// Mixins
import formMixin from '~/assets/js/mixins/formMixin';
import routerMixin from '~/assets/js/mixins/routerMixin';

// Models
import loginModel from './models/login';

// Components
import AuthTemplate from '~/components/Auth/AuthTemplate';
import SBanner from "~components/Common/SBanner";

export default {
  name: 'LoginForm',

  components: {
    SBanner,
    AuthTemplate,
  },

  mixins: [
    formMixin,
    routerMixin,
  ],

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    loginError: {
      type: [Error, Object],
      default: null,
    },

    serverError: {
      type: String,
      default: '',
    },

    forbiddenError: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    formData: loginModel(),
    regex,
    isPasswordShown: false,
    isLinkUnderlined: false,
    selectedTab: 'login',
    forbiddenErrorText: 'Учетная запись заблокирована. Обратитесь к Администратору компании',
  }),

  watch: {
    loginError: {
      handler(error) {
        this.addErrors(error);
      },
      deep: true,
    },
  },

  async mounted() {
    try {
      this.isLinkUnderlined = await document.fonts.ready.then(() => true);
    } catch (err) {
      console.log(err);
    }
  },

  beforeDestroy() {
    this.selectedTab = '';
  },

  methods: {
    ...mapMutations({
      setBannerStatus: 'applications/setBannerStatus',
    }),
    unmaskValue,

    showHidePassword() {
      this.isPasswordShown = !this.isPasswordShown;
    },

    async login() {
      const isValid = await this.validateForm();

      if (!isValid) return;

      const params = {
        ...this.formData,
        phone: this.unmaskValue(this.formData.phone),
      };

      if (this.formData.password === '000000') {
        this.setBannerStatus(true);
      }

      this.$emit('login', params);
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';

.login {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__error {
    font-size: 1.3rem;
    line-height: 1.3;
  }

  .t-button.login__button {
    min-width: 172px;
    justify-content: center;
    margin-right: 40px;
  }
}
</style>
