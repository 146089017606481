<template>
  <div>
    <h1
      class="auth__title"
      :class="{
        'auth__title--mb':
          !isTabs(currentSection)}"
    >
      {{getCurrentComponentData.componentTitle}}
    </h1>
    <TTabs
      v-if="isTabs(currentSection)"
      class="mb-4"
      :value="selectedTab"
      uppercase
      @input="changeTab"
    >
      <TTab
        :active="selectedTab === 'login'"
        :label="$t('auth.Log in')"
        slug="login"
      />
      <TTab
        :active="selectedTab === 'registration'"
        :label="$t('auth.reg.Create an account')"
        slug="registration"
      />
    </TTabs>
    <component
      :is="getCurrentComponentData.name"
      :is-loading="isLoginControlLoading || isVerifyRegisterControlLoading"
      :login-error="loginError"
      :server-error="serverError"
      :register-errors="registerErrors"
      :forbidden-error="forbiddenError"
      :register-verification-errors="registerVerificationErrors"
      :is-control-loading="isRegisterControlLoading"
      :phone-errors="recoverySendPhoneErrors"
      :user-saved-phone="savedUserPhone"
      :verify-recovery-errors="recoveryVerifyErrors"
      :create-password-errors="createPasswordErrors"
      @send-recovery-phone="sendRecoveryPhone"
      @login="login"
      @register="register"
      @login-form="changeSection"
      @password-recovery="changeSection"
      @change-phone="changeSection"
      @verify-password-recovery="verifyPasswordRecovery"
      @return-to-phone="returnToPhone"
      @create-new-password="createNewPassword"
      @verify-register="verifyRegister"
    />
  </div>
</template>

<script>
// Mixins
import { mapGetters, mapActions, mapMutations } from 'vuex';
import routerMixin from '~/assets/js/mixins/routerMixin';

// Components
import LoginForm from '~/components/Auth/LoginForm';

// constants
import { RELOAD_TRIGGER, APP_NAME } from '~/assets/js/constants';

export default {
  name: 'LoginPage',

  layout: 'auth',

  components: {
    LoginForm,
    PasswordRecovery: () => import('~/components/Auth/PasswordRecovery'),
    VerifyPasswordRecovery: () => import('~/components/Auth/VerifyPasswordRecovery'),
    CreateNewPassword: () => import('~/components/Auth/CreateNewPassword'),
    Register: () => import('~/components/Auth/Register'),
    VerifyRegister: () => import('~/components/Auth/VerifyRegister'),
  },

  mixins: [
    routerMixin,
  ],

  data: () => ({
    currentSection: '',
    loginError: null,
    registerErrors: {},
    savedUserPhone: '',
    isPasswordRecovery: true,
    registerVerificationErrors: {},
    recoverySendPhoneErrors: {},
    recoveryVerifyErrors: {},
    createPasswordErrors: {},
    selectedTab: '',
    forbiddenError: false,
  }),

  computed: {
    ...mapGetters({
      isLoginControlLoading: 'auth/login/GET_CONTROL_LOADING',
      serverError: 'general/GET_ERROR_MSG',
      isRegisterControlLoading: 'auth/register/GET_CONTROL_LOADING',
      isVerifyRegisterControlLoading: 'auth/register/GET_VR_CONTROL_LOADING',
      isPRControlLoading: 'auth/passwordRecovery/GET_PR_CONTROL_LOADING',
      isVPRControlLoading: 'auth/passwordRecovery/GET_VPR_CONTROL_LOADING',
      isCreatePasswordControlLoading: 'auth/passwordRecovery/GET_CNP_CONTROL_LOADING',
      registeredRole: 'auth/register/GET_REGISTERED_ROLE',
    }),

    getCurrentComponentData() {
      switch (this.currentSection) {
        case 'login': {
          return {
            name: 'LoginForm',
            componentTitle: this.$t('auth.Login to system'),
            title: this.$t('auth.Log in'),
          };
        }
        case 'password-recovery': {
          return {
            name: 'PasswordRecovery',
            componentTitle: this.$t('auth.rec.Forgot your password?'),
            title: this.$t('auth.Password recovery'),
          };
        }
        case 'verify-password-recovery': {
          return {
            name: 'VerifyPasswordRecovery',
            componentTitle: this.$t('auth.Verification'),
            title: this.$t('auth.Password recovery'),
          };
        }
        case 'create-new-password': {
          return {
            name: 'CreateNewPassword',
            componentTitle: this.$t('auth.rec.Forgot your password?'),
            title: this.$t('auth.Password recovery'),
          };
        }
        case 'registration': {
          return {
            name: 'Register',
            componentTitle: this.$t('auth.Login to system'),
            title: this.$t('auth.Registration'),
          };
        }
        case 'verify-register': {
          return {
            name: 'VerifyRegister',
            componentTitle: this.$t('auth.Confirmation of registration'),
            title: this.$t('auth.Registration'),
          };
        }

        default: {
          return {
            name: 'LoginForm',
            componentTitle: this.$t('auth.Login to system'),
            title: this.$t('auth.Log in'),
          };
        }
      }
    },
  },

  created() {
    this.setAuthParams();
  },

  beforeDestroy() {
    const mutationsToUnset = ['getBackToPhone', 'getBackToRecovery'];

    mutationsToUnset.forEach(cur => {
      this[cur](false);
    });
  },

  methods: {
    ...mapActions({
      logIn: 'auth/login/LOG_IN',
      sendRegisterRequest: 'auth/register/REGISTER',
      sendRegisterVerification: 'auth/register/VERIFY_REGISTER',
      setAuthCookie: 'general/SET_AUTH_COOKIE',
      setAuthHeader: 'general/SET_AUTH_HEADER',
      removeAuthCookie: 'general/REMOVE_AUTH_COOKIE',
      removeAuthHeader: 'general/REMOVE_AUTH_HEADER',
      getUserInfo: 'main/getInfo',
      sendPhoneRequest: 'auth/passwordRecovery/SEND_PASSWORD_RECOVERY_PHONE',
      sendPRVerification: 'auth/passwordRecovery/VERIFY_PASSWORD_RECOVERY',
      sendNewPassword: 'auth/passwordRecovery/CREATE_NEW_PASSWORD',
    }),

    ...mapMutations({
      setControlLoading: 'auth/login/setControlLoading',
      getBackToPhone: 'auth/passwordRecovery/setPRPhoneSent',
      getBackToRecovery: 'auth/passwordRecovery/setPRVerified',
    }),

    async login(params) {
      try {
        const { token } = await this.logIn({ ...params });

        await this.setAuthCookie(token);
        await this.setAuthHeader(token);
        await this.getUserInfo();
        await this.$router.push('/', () => {
          this.setControlLoading(false);
        });

        localStorage.setItem(RELOAD_TRIGGER, String(new Date().valueOf()));
      } catch (error) {
        const forbiddenErr = error.toString().includes('403');
        if (!this.serverError && !forbiddenErr) {
          this.loginError = error;
        }
        if (forbiddenErr) {
          this.forbiddenError = true;
        }
        this.setControlLoading(false);
        this.removeAuthCookie();
        this.removeAuthHeader();
      }
    },

    register(params) {
      this.sendRegisterRequest({ ...params })
        .then(() => {
          this.savedUserPhone = params.phone;
          this.currentSection = 'verify-register';
        })
        .catch(error => {
          if (error.response?.data.errors) {
            this.registerErrors = error;
          }
          this.savedUserPhone = '';
        });
    },

    async verifyRegister(params) {
      try {
        const { token } = await this.sendRegisterVerification({ ...params });

        await this.setAuthCookie(token);
        await this.setAuthHeader(token);

        const query = this.getQuery();

        if (query.fromPublickOrder) {
          this.$router.push({
            name: 'orders-index-info-id',
            params: {
              id: query.orderId,
            },
          });
        } else if (this.registeredRole === 'transporter') {
          this.$router.push('/trips');
        } else {
          this.$router.push('/profile');
        }
      } catch (error) {
        if (error.response?.data.errors) {
          this.registerVerificationErrors = error;
        }

        this.removeAuthCookie();
        this.removeAuthHeader();
      }
    },

    sendRecoveryPhone(params) {
      this.sendPhoneRequest({ ...params })
        .then(() => {
          this.savedUserPhone = params.phone;
          this.currentSection = 'verify-password-recovery';
        })
        .catch(error => {
          if (!this.serverError) this.recoverySendPhoneErrors = error;
          this.savedUserPhone = '';
        });
    },

    verifyPasswordRecovery(params) {
      this.sendPRVerification({ ...params })
        .then(() => {
          this.currentSection = 'create-new-password';
        })
        .catch(error => {
          if (!this.serverError) this.recoveryVerifyErrors = error;
        });
    },

    returnToPhone() {
      this.getBackToPhone(false);
      this.currentSection = 'password-recovery';
    },

    async createNewPassword(params) {
      const data = {
        ...params,
        phone: this.savedUserPhone,
      };

      try {
        const { token } = await this.sendNewPassword({ ...data });

        await this.setAuthCookie(token);
        await this.setAuthHeader(token);
        await this.getUserInfo();
        await this.$router.push('/');
      } catch (error) {
        if (!this.serverError) {
          this.createPasswordErrors = error;
        }

        this.removeAuthCookie();
        this.removeAuthHeader();
      }
    },

    changeSection(event) {
      this.currentSection = event;
    },

    async changeTab(activeTab) {
      if (activeTab !== this.selectedTab) {
        this.selectedTab = activeTab;
        this.currentSection = activeTab;
        await this.setQuery({ ...this.query, activeTab });
      }
    },

    setAuthParams() {
      const query = this.getQuery();
      if (query.activeTab && this.isTabs(query.activeTab)) {
        this.selectedTab = query.activeTab;
        this.currentSection = query.activeTab;
      } else {
        this.setQuery({ activeTab: 'login' });
        this.selectedTab = 'login';
        this.currentSection = 'login';
      }
    },

    isTabs(tab) {
      return ['login', 'registration'].includes(tab);
    },
  },

  head() {
    return {
      title: `${this.getCurrentComponentData.title} - ${APP_NAME}`,
    };
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';

.auth__title{
  font: $h1-light;

  &--mb{
    margin-bottom: 105px;
  }
}

.t-tabs{
  margin-top: 46px;

   @media screen and (max-height: $media-sm) {
      margin-top: 10px;
    }

  &__content {
      padding: 0;
    }
}
</style>
